<template>
    <div>
        <check-permission/>
        <div v-if="currentUser.is_superuser" class="home">
            <AdminHome/>
        </div>
        <div v-if="!currentUser.is_superuser && currentUser.features.dashboard" class="home">
            <ConsumerHome/>
        </div>
        <div v-if="!currentUser.features.dashboard && !currentUser.is_superuser"
             class="card text-center bg-primary">
            Sorry, You don't have permission to view this page. Kindly contact your Owner.
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

import { mapGetters } from 'vuex';
import AdminHome from './AdminHome';
import ConsumerHome from './ConsumerHome';

export default {
    name       : 'Home',
    components : { ConsumerHome, AdminHome },
    computed   : {
        ...mapGetters(['currentUser'])
    }
};

</script>

<style>
.count-card {
    text-align: center;
    margin: 3rem;
}
</style>
